<template>
  <layout page="teacher-students-list">
    <page-title icon="lni lni-users" :breadcrumb="breadcrumb">Alunos</page-title>
    <card-list>
      <card-list-header title="Alunos" />
      <div class="card-content">
        <div class="row mb-2">
          <div v-if="students.length > 0" class="table-container">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <td>Ações</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(student) in students"
                  :key="student.id"
                >
                  <td>{{ student.name }}</td>
                  <td>{{ student.email }}</td>
                  <td>
                    <router-link
                      class="button is-primary is-small"
                      :to="`/p/t/turmas/${idClass}/alunos/${student.id}/boletim`"
                      title="Boletim"
                    >
                      <i class="lni lni-library"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <empty-list v-else entity="aluno" />
        </div>
      </div>
    </card-list>
  </layout>
</template>

<script>
import api from '@/api'
import { DASHBOARD, CLASS_LIST, CLASS, STUDENT_LIST } from '@/config/breadcrumb/teacher'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import CardList from '@/components/CardList'
import CardListHeader from '@/components/CardList/Header'
import EmptyList from '@/components/ui/EmptyList'

export default {
  name: 'TeacherStudentList',
  components: {
    Layout,
    PageTitle,
    CardList,
    CardListHeader,
    EmptyList
  },
  created () {
    this.idClass = this.$route.params.idClass

    api.get(`/classes/${this.idClass}/students`).then(res => {
      this.students = res.data
    })
  },
  data () {
    return {
      idClass: 0,
      students: []
    }
  },
  computed: {
    breadcrumb () {
      return [
        DASHBOARD,
        CLASS_LIST,
        CLASS(this.idClass),
        {
          ...STUDENT_LIST,
          isActive: true
        }
      ]
    }
  }
}
</script>
