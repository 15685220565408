<template>
  <header class="card-header">
    <p class="card-header-title">
      <span>{{ title }}</span>
    </p>
    <div class="card-header-icon" v-if="btnLink && btnText">
      <router-link class="button is-primary" :to="btnLink">
        <i class="lni lni-plus mr-1"></i> <span>{{ btnText }}</span>
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: 'CardListHeader',
  props: {
    title: String,
    btnText: String,
    btnLink: String
  }
}
</script>
