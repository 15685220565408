<template>
  <layout page="teacher-activities-list">
    <page-title icon="lni lni-school-bench-alt" :breadcrumb="breadcrumb">Atividades</page-title>
    <card-list>
       <card-list-header title="Atividades" />
       <div class="card-content">
          <div class="row mb-2">
            <div class="columns">
              <div class="column">
                <div class="field is-horizontal">
                  <div class="field-body is-justify-content-flex-end">
                  <div class="field is-narrow">
                    <div class="control">
                      <div class="select is-fullwidth">
                        <select v-model="filter">
                          <option value="all" selected>Todas Atividades</option>
                          <option value="my">Minhas Atividades</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activities.length > 0" class="table-container">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Titulo</th>
                  <th>Tipo</th>
                  <th>Data de criação</th>
                  <th>Data de entrega</th>
                  <th>Anexo</th>
                  <th>Nota</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="activity in activitiesFiltered"
                  :key="activity.id"
                >
                  <td>{{ activity.name }}</td>
                  <td>{{ activity.type }}</td>
                  <td>{{ activity.createdAt | formatDate }}</td>
                  <td>
                    <span v-if="activity.deadline !== undefined && activity.deadline !== ''">{{ activity.deadline | formatDate }}</span>
                    <span v-else><i>sem data</i></span>
                  </td>
                  <td>
                    <a
                      v-if="activity.anex"
                      :href="`${API_URL}/public/activities/annexes/${activity.anex}`"
                      target="__blank"
                    >
                      {{ activity.anex }}
                    </a>
                    <span v-else><i>sem anexo</i></span>
                  </td>
                  <td>{{ activity.scoreRange }}</td>
                </tr>
              </tbody>
            </table>
            </div>
          <empty-list v-else entity="atividade" />
       </div>
    </card-list>
  </layout>
</template>

<script>
import { mapState } from 'vuex'
import { api, API_URL } from '@/api'
import { format } from 'date-fns'
import { DASHBOARD, CLASS_LIST, CLASS_FORM, ACTIVITY_LIST } from '@/config/breadcrumb/teacher'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import CardList from '@/components/CardList'
import CardListHeader from '@/components/CardList/Header'
import EmptyList from '@/components/ui/EmptyList'

export default {
  name: 'TeacherActivityList',
  components: {
    Layout,
    PageTitle,
    CardList,
    CardListHeader,
    EmptyList
  },
  created () {
    this.idClass = this.$route.params.idClass

    api.get(`/classes/${this.idClass}/activities`).then(res => {
      this.activities = res.data
    })
  },
  data () {
    return {
      idClass: 0,
      activities: [],
      filter: 'all'
    }
  },
  computed: {
    ...mapState(['user']),
    breadcrumb () {
      return [
        DASHBOARD,
        CLASS_LIST,
        {
          ...CLASS_FORM,
          text: 'Turma',
          path: `/p/t/turmas/${this.idClass}`
        },
        {
          ...ACTIVITY_LIST,
          isActive: true
        }
      ]
    },
    API_URL () {
      return API_URL
    },
    activitiesFiltered () {
      if (this.filter === 'all') return this.activities

      return this.activities.filter(activity => {
        if (activity.TeacherId === this.user.id) return activity
      })
    }
  },
  filters: {
    formatDate (value) {
      if (value === '' || value === undefined) return
      return format(new Date(value), 'd/MM/y')
    }
  }
}
</script>
