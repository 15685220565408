import Swal from 'sweetalert2'

const SwalLoading = Swal.mixin({
  title: 'Carregando...',
  allowEscapeKey: false,
  allowEnterKey: false,
  allowOutsideClick: false,
  showConfirmButton: false,
  showCloseButton: false,
  didOpen: () => {
    Swal.showLoading()
  }
})

export default SwalLoading
