<template>
  <div>
    <p class="subtitle has-text-centered my-5">
      <i class="lni lni-information"></i> Nenhum {{ entity }} no sistema
    </p>
  </div>
</template>

<script>
export default {
  name: 'EmptyList',
  props: {
    entity: {
      type: String,
      required: true
    }
  }
}
</script>
