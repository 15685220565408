<template>
  <div class="markdown-editor">
    <div class="editor-menu mb-1">
      <div class="menu-item select is-light is-small">
        <select
          v-model="titleType"
          @change="insert(titleType, false); titleType = ''"
        >
          <option value="" selected disabled>Inserir Titulo</option>
          <option
            v-for="title in titles"
            :key="title.value"
            :value="title.value"
          >
            {{ title.name }}
          </option>
        </select>
      </div>
      <button class="menu-item button is-light is-small" @click="insert('**')">
        <i class="lni lni-bold" />
      </button>
      <button class="menu-item button is-light is-small" @click="insert('_')">
        <i class="lni lni-italic" />
      </button>
      <button class="menu-item button is-light is-small" @click="showPreview">
        <i class="lni lni-eye" />
      </button>
    </div>
    <textarea
      v-model="text"
      class="textarea"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      @input="handleInput"
      ref="textarea"
    />

    <div class="modal is-clipped" :class="{'is-active': preview}">
      <div class="modal-background" @click="preview = false"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Preview</p>
          <button class="delete" aria-label="close" @click="preview = false"></button>
        </header>
        <section class="modal-card-body content" v-html="compiled">
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked'

export default {
  name: 'MarkdownEditor',
  props: {
    name: String,
    id: String,
    placeholder: String,
    value: String
  },
  mounted () {
    this.text = this.value
  },
  data () {
    return {
      text: '',
      titleType: '',
      titles: [
        { value: '#', name: 'Título Principal' },
        { value: '##', name: 'Título' },
        { value: '###', name: 'Titulo 2' },
        { value: '####', name: 'Titulo 3' }
      ],
      preview: false,
      compiled: ''
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.text)
    },
    insert (payload, openAndClose = true) {
      if (openAndClose) {
        this.text += `${payload}${payload}`

        setTimeout(() => {
          const end = this.text.length - payload.length
          this.$refs.textarea.selectionStart = end
          this.$refs.textarea.selectionEnd = end
          this.$refs.textarea.focus()
        }, 100)
      } else {
        this.text += `${payload} `
        this.$refs.textarea.focus()
      }
    },
    showPreview () {
      this.compiled = marked(this.text)
      this.preview = true
    }
  }
}
</script>

<style lang="scss" scoped>
.markdown-editor {
  .editor-menu {
    .menu-item {
      margin-right: .2rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
