const DASHBOARD = {
  path: '/p/t',
  text: 'Dashboard'
}

const CLASS = (idClass) => {
  return {
    path: `/p/t/turmas/${idClass}`,
    text: 'Turma'
  }
}

const CLASS_LIST = {
  path: '/p/t/turmas',
  text: 'Turmas'
}

const ACTIVITY_LIST = {
  path: '/p/t/turmas/:idClass/atividades',
  text: 'Atividades'
}

const ACTIVITY_FORM = {
  path: '/p/t/turmas/:idClass/atividades/nova',
  text: 'Nova Atividade'
}

const STUDENT_LIST = {
  path: '/p/t/turmas/:idClass/alunos',
  text: 'Alunos'
}

const REPORT_CARD = (idClass, idStudent, isActive = false) => {
  return {
    path: `/p/t/turmas/${idClass}/alunos/${idStudent}/boletim`,
    text: 'Boletim',
    isActive
  }
}

export {
  DASHBOARD,
  CLASS,
  CLASS_LIST,
  ACTIVITY_LIST,
  ACTIVITY_FORM,
  STUDENT_LIST,
  REPORT_CARD
}
